import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { Tooltip } from 'react-tooltip';
import { Radio } from 'antd';

const MKD_TO_EUR = 61.5;

// Using a reliable world map TopoJSON from a CDN
const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-50m.json";

// Complete mapping of country codes to ISO3
const countryCodeMapping = {
  'MK': 'MKD',  // North Macedonia
  'HR': 'HRV',  // Croatia
  'XK': 'XKX',  // Kosovo
  'RS': 'SRB',  // Serbia
  'SI': 'SVN',  // Slovenia
  'BG': 'BGR',  // Bulgaria
  'AL': 'ALB',  // Albania
  'BA': 'BIH',  // Bosnia and Herzegovina
  'ME': 'MNE',  // Montenegro
  'RO': 'ROU',  // Romania
  'GR': 'GRC',  // Greece
  'AT': 'AUT',  // Austria
  'DE': 'DEU',  // Germany
  'IT': 'ITA',  // Italy
  'HU': 'HUN',  // Hungary
  'TR': 'TUR',  // Turkey
  'SK': 'SVK',  // Slovakia
  'CZ': 'CZE',  // Czech Republic
  'PL': 'POL',  // Poland
  'UA': 'UKR',  // Ukraine
  'MD': 'MDA',  // Moldova
  'BY': 'BLR',  // Belarus
  'LT': 'LTU',  // Lithuania
  'LV': 'LVA',  // Latvia
  'EE': 'EST',  // Estonia
  'FI': 'FIN',  // Finland
  'SE': 'SWE',  // Sweden
  'NO': 'NOR',  // Norway
  'DK': 'DNK',  // Denmark
  'GB': 'GBR',  // United Kingdom
  'IE': 'IRL',  // Ireland
  'FR': 'FRA',  // France
  'ES': 'ESP',  // Spain
  'PT': 'PRT',  // Portugal
  'CH': 'CHE',  // Switzerland
  'NL': 'NLD',  // Netherlands
  'BE': 'BEL',  // Belgium
  'LU': 'LUX',  // Luxembourg
};

const CountryHeatmap = ({ data, onCountryClick }) => {
  const [mode, setMode] = useState('count');
  const [position, setPosition] = useState({
    coordinates: [20, 45], // Centered on Europe
    zoom: 4
  });

  const processData = () => {
    const countryData = {};

    data.forEach(invoice => {
      const countryCode = invoice.location_of_company;
      if (!countryCode) return;

      // Map the country code to ISO format
      const isoCode = countryCodeMapping[countryCode];
      if (!isoCode) return; // Skip if we don't have a mapping

      if (!countryData[isoCode]) {
        countryData[isoCode] = {
          count: 0,
          value: 0,
          originalCode: countryCode
        };
      }

      countryData[isoCode].count += 1;

      // Convert amount to EUR based on currency
      let amountInEur = 0;
      if (invoice.currency === 'MK-MKD') {
        amountInEur = invoice.amount / MKD_TO_EUR;
      } else if (invoice.currency === 'EU-EUR' || invoice.currency === 'MK-EUR') {
        amountInEur = invoice.amount;
      }

      countryData[isoCode].value += amountInEur;
    });

    return countryData;
  };

  const getColorScale = (data, mode) => {
    const values = Object.values(data).map(d => mode === 'count' ? d.count : d.value);
    const max = Math.max(...values, 1); // Ensure we always have a non-zero max

    return scaleLinear()
      .domain([0, max])
      .range(['#e6f7ff', '#1890ff']);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const countryData = processData();
  const colorScale = getColorScale(countryData, mode);

  return (
    <div className="heatmap-container">
      <div className="heatmap-controls">
        <Radio.Group value={mode} onChange={handleModeChange}>
          <Radio.Button value="count">Number of Invoices</Radio.Button>
          <Radio.Button value="value">Invoice Value (EUR)</Radio.Button>
        </Radio.Group>
      </div>

      <div className="map-container">
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 400,
            center: [0, 20]
          }}
          width={800}
          height={400}
          style={{ width: '100%', height: 'auto' }}
        >
          <ZoomableGroup
            center={position.coordinates}
            zoom={position.zoom}
            onMoveEnd={({ coordinates, zoom }) => setPosition({ coordinates, zoom })}
            maxZoom={12}
            minZoom={1}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const isoCode = geo.properties.iso_a3;
                  const hasData = countryData[isoCode];
                  const value = hasData ? 
                    (mode === 'count' ? hasData.count : hasData.value) : 0;

                  // Get the original country code for click handler
                  const originalCode = Object.entries(countryCodeMapping)
                    .find(([code, iso]) => iso === isoCode)?.[0];

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onClick={() => hasData ? onCountryClick(originalCode) : null}
                      data-tooltip-id="country-tooltip"
                      data-tooltip-content={
                        hasData
                          ? `${geo.properties.name}: ${mode === 'count' 
                              ? `${hasData.count} invoices`
                              : `€${hasData.value.toLocaleString(undefined, {
                                  maximumFractionDigits: 2
                                })}`}`
                          : geo.properties.name
                      }
                      style={{
                        default: {
                          fill: hasData ? colorScale(value) : '#F5F4F6',
                          stroke: '#D6D6DA',
                          strokeWidth: hasData ? 0.5 : 0.2,
                          outline: 'none',
                        },
                        hover: {
                          fill: hasData ? '#1890ff' : '#E6E6E6',
                          stroke: '#D6D6DA',
                          strokeWidth: hasData ? 0.5 : 0.2,
                          outline: 'none',
                          cursor: hasData ? 'pointer' : 'default',
                        },
                        pressed: {
                          fill: '#0050b3',
                          stroke: '#D6D6DA',
                          strokeWidth: 0.5,
                          outline: 'none',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <Tooltip 
          id="country-tooltip"
          style={{
            backgroundColor: 'white',
            color: '#333',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            padding: '8px 12px',
            borderRadius: '4px',
            fontSize: '14px'
          }}
        />
      </div>
    </div>
  );
};

export default CountryHeatmap;
