import React from 'react';
import { PieChart as RechartsChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const PieChart = ({ data, metric, conversionRate = 61.5, onItemClick }) => {
  const calculateChartData = () => {
    if (!data || data.length === 0) return [];

    const aggregatedData = {};

    data.forEach(item => {
      const key = metric === 'vendorOrders' || metric === 'vendorIncome' 
        ? item.vendor 
        : item.location_of_company;
      
      if (!key) return;

      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          orders: 0,
          incomeMKD: 0,
          incomeEUR: 0
        };
      }

      aggregatedData[key].orders++;
      
      const amount = parseFloat(item.amount);
      if (item.currency === 'MK-MKD') {
        aggregatedData[key].incomeMKD += amount;
      } else {
        aggregatedData[key].incomeEUR += amount;
      }
    });

    return Object.entries(aggregatedData)
      .map(([name, values]) => {
        let value;
        switch (metric) {
          case 'vendorOrders':
          case 'orders':
            value = values.orders;
            break;
          case 'vendorIncome':
          case 'incomeEUR':
            value = values.incomeEUR + (values.incomeMKD / conversionRate);
            break;
          case 'incomeMKD':
            value = values.incomeMKD;
            break;
          default:
            value = 0;
        }
        return { name, value };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);
  };

  const chartData = calculateChartData();
  const total = chartData.reduce((sum, item) => sum + item.value, 0);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <p style={{ margin: 0 }}><strong>{data.name}</strong></p>
          <p style={{ margin: 0, color: payload[0].color }}>
            {data.value.toLocaleString()}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return percent > 0.05 ? (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  };

  return (
    <div className="pie-chart">
      <ResponsiveContainer width="100%" height={300}>
        <RechartsChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={({ name, value }) => {
              const percentage = ((value / total) * 100).toFixed(1);
              const displayValue = metric.includes('income')
                ? value.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : value.toLocaleString();
              return `${name}: ${displayValue} (${percentage}%)`;
            }}
            onClick={(data) => onItemClick(data.name)}
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => {
              if (metric.includes('income')) {
                return [
                  value.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }),
                  name
                ];
              }
              return [value.toLocaleString(), name];
            }}
          />
          <Legend />
        </RechartsChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChart;
