import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import './Analytics.css';

const KPICard = ({ title, data, metric, currency, unit, inverseColors }) => {
  const calculateValue = () => {
    if (!data || data.length === 0) return 0;

    switch (metric) {
      case 'salesMKD':
        return data.reduce((sum, item) => 
          item.currency === 'MK-MKD' ? sum + parseFloat(item.amount) : sum, 0);
      
      case 'salesEUR':
        return data.reduce((sum, item) => {
          if (item.currency === 'EU-EUR' || item.currency === 'MK-EUR') {
            return sum + parseFloat(item.amount);
          } else if (item.currency === 'MK-MKD') {
            return sum + (parseFloat(item.amount) / 61.5);
          }
          return sum;
        }, 0);
      
      case 'count':
        return data.length;
      
      case 'latency':
        const validLatencies = data.filter(item => 
          item.date_of_payment_received && item.date_of_issue
        ).map(item => 
          Math.floor((new Date(item.date_of_payment_received) - new Date(item.date_of_issue)) / (1000 * 60 * 60 * 24))
        );
        return validLatencies.length > 0 
          ? Math.round(validLatencies.reduce((sum, val) => sum + val, 0) / validLatencies.length)
          : 0;
      
      default:
        return 0;
    }
  };

  const calculateComparison = () => {
    if (!data || data.length === 0) return null;

    switch (metric) {
      case 'salesMKD':
        const previousSalesMKD = data.filter(item => 
          item.currency === 'MK-MKD' && item.date_of_issue < new Date().setDate(new Date().getDate() - 30)
        ).reduce((sum, item) => sum + parseFloat(item.amount), 0);
        return (calculateValue() - previousSalesMKD) / previousSalesMKD * 100;
      
      case 'salesEUR':
        const previousSalesEUR = data.filter(item => 
          (item.currency === 'EU-EUR' || item.currency === 'MK-EUR') && item.date_of_issue < new Date().setDate(new Date().getDate() - 30)
        ).reduce((sum, item) => sum + parseFloat(item.amount), 0);
        return (calculateValue() - previousSalesEUR) / previousSalesEUR * 100;
      
      case 'count':
        const previousCount = data.filter(item => 
          item.date_of_issue < new Date().setDate(new Date().getDate() - 30)
        ).length;
        return (calculateValue() - previousCount) / previousCount * 100;
      
      case 'latency':
        const previousLatencies = data.filter(item => 
          item.date_of_payment_received && item.date_of_issue < new Date().setDate(new Date().getDate() - 30)
        ).map(item => 
          Math.floor((new Date(item.date_of_payment_received) - new Date(item.date_of_issue)) / (1000 * 60 * 60 * 24))
        );
        const previousAverageLatency = previousLatencies.length > 0 
          ? Math.round(previousLatencies.reduce((sum, val) => sum + val, 0) / previousLatencies.length)
          : 0;
        return (calculateValue() - previousAverageLatency) / previousAverageLatency * 100;
      
      default:
        return null;
    }
  };

  const value = calculateValue();
  const comparison = calculateComparison();

  const formatComparison = (value) => {
    if (value === null || value === undefined) return null;
    return value.toFixed(1);
  };

  const getComparisonColor = (value) => {
    if (value === null || value === undefined) return '';
    if (inverseColors) {
      // For metrics where decrease is positive (like payment latency)
      if (value < 0) return 'positive';
      if (value > 0) return 'negative';
    } else {
      // For regular metrics where increase is positive
      if (value > 0) return 'positive';
      if (value < 0) return 'negative';
    }
    return '';
  };

  return (
    <div className="kpi-card">
      <div className="kpi-header">
        <span className="kpi-title">{title}</span>
      </div>
      <div className="kpi-content">
        <div className="kpi-value">
          {typeof value === 'number' ? value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          }) : value}
          {currency && <span className="kpi-currency">{currency}</span>}
          {unit && <span className="kpi-unit">{unit}</span>}
        </div>
        {comparison !== null && comparison !== undefined && (
          <div className={`kpi-comparison ${getComparisonColor(comparison)}`}>
            {comparison > 0 ? <FaArrowUp /> : <FaArrowDown />}
            {Math.abs(formatComparison(comparison))}%
          </div>
        )}
      </div>
    </div>
  );
};

export default KPICard;
