import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import './Analytics.css';

const { RangePicker } = DatePicker;

const TreeFilter = ({ title, items, selectedItems, onSelectionChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCheckboxChange = (key) => {
    const newSelection = { ...selectedItems };
    if (newSelection[key]) {
      delete newSelection[key];
    } else {
      newSelection[key] = true;
    }
    onSelectionChange(newSelection);
  };

  const selectedCount = Object.keys(selectedItems).length;

  return (
    <div className="filter-section">
      <div className="filter-card">
        <div className="filter-header" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="filter-header-content">
            <h4>{title}</h4>
            {selectedCount > 0 && (
              <span className="selected-count">({selectedCount} selected)</span>
            )}
          </div>
          <span className={`arrow ${isExpanded ? 'expanded' : ''}`}>▼</span>
        </div>
        
        {isExpanded && (
          <div className="filter-options">
            {Object.entries(items).map(([key, item]) => (
              <div key={key} className="filter-checkbox-row">
                <label>
                  <input
                    type="checkbox"
                    checked={!!selectedItems[key]}
                    onChange={() => handleCheckboxChange(key)}
                  />
                  <span className="checkbox-label">{item.label}</span>
                  {item.type && (
                    <span className="type-badge">{item.type}</span>
                  )}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const AnalyticsFilters = ({ 
  filterState, 
  onFilterChange, 
  customDateRange, 
  setCustomDateRange, 
  availableFilters 
}) => {
  const [dateType, setDateType] = useState('year');
  const [selectedPeriod, setSelectedPeriod] = useState(moment().year().toString());
  const [showCustomRange, setShowCustomRange] = useState(false);

  const getPeriodOptions = () => {
    const currentYear = moment().year();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
    
    switch (dateType) {
      case 'year':
        return years.map(year => ({
          value: year,
          label: year.toString()
        }));
      
      case 'quarter':
        return years.flatMap(year => 
          [1, 2, 3, 4].map(quarter => ({
            value: `${year}-Q${quarter}`,
            label: `${year} Q${quarter}`
          }))
        );
      
      case 'month':
        return years.flatMap(year =>
          Array.from({ length: 12 }, (_, i) => ({
            value: `${year}-${String(i + 1).padStart(2, '0')}`,
            label: `${year} ${moment().month(i).format('MMMM')}`
          }))
        );
      
      case 'week':
        const weeks = [];
        const startOfYear = moment().startOf('year');
        const endOfYear = moment().endOf('year');
        let current = startOfYear.clone();
        
        while (current.isBefore(endOfYear)) {
          weeks.push({
            value: `${current.year()}-W${current.isoWeek()}`,
            label: `Week ${current.isoWeek()}, ${current.year()}`
          });
          current.add(1, 'week');
        }
        return weeks;
      
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!selectedPeriod || dateType === 'custom') return;

    let start, end;
    
    switch (dateType) {
      case 'year':
        start = moment().year(selectedPeriod).startOf('year');
        end = moment().year(selectedPeriod).endOf('year');
        break;
      
      case 'quarter':
        const [year, quarter] = selectedPeriod.split('-Q');
        start = moment().year(parseInt(year)).quarter(parseInt(quarter)).startOf('quarter');
        end = moment().year(parseInt(year)).quarter(parseInt(quarter)).endOf('quarter');
        break;
      
      case 'month':
        const [monthYear, monthNum] = selectedPeriod.split('-');
        start = moment(`${monthYear}-${monthNum}-01`).startOf('month');
        end = moment(`${monthYear}-${monthNum}-01`).endOf('month');
        break;
      
      case 'week':
        const [weekYear, weekNum] = selectedPeriod.split('-W');
        start = moment().year(parseInt(weekYear)).isoWeek(parseInt(weekNum)).startOf('isoWeek');
        end = moment().year(parseInt(weekYear)).isoWeek(parseInt(weekNum)).endOf('isoWeek');
        break;
    }

    if (start && end) {
      setCustomDateRange({
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD')
      });
      onFilterChange({
        ...filterState,
        timeframe: {
          type: dateType,
          start: start.toISOString(),
          end: end.toISOString()
        }
      });
    }
  }, [dateType, selectedPeriod]);

  useEffect(() => {
    const currentYear = moment().year();
    const start = moment().year(currentYear).startOf('year');
    const end = moment().year(currentYear).endOf('year');

    setCustomDateRange({
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD')
    });
    
    onFilterChange({
      ...filterState,
      timeframe: {
        type: 'year',
        start: start.toISOString(),
        end: end.toISOString()
      }
    });
  }, []); 

  const handleDateTypeChange = (e) => {
    const newType = e.target.value;
    setDateType(newType);
    setSelectedPeriod(null);
    
    if (newType === 'custom') {
      setShowCustomRange(true);
    } else {
      setShowCustomRange(false);
      onFilterChange({
        ...filterState,
        timeframe: newType
      });
    }
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const handleCustomDateChange = (type, value) => {
    const newCustomDateRange = {
      ...customDateRange,
      [type]: value
    };
    setCustomDateRange(newCustomDateRange);

    if (newCustomDateRange.start && newCustomDateRange.end) {
      onFilterChange({
        ...filterState,
        timeframe: {
          type: 'custom',
          start: new Date(newCustomDateRange.start).toISOString(),
          end: new Date(newCustomDateRange.end).toISOString()
        }
      });
    }
  };

  const handleClearFilters = () => {
    const currentYear = moment().year();
    const start = moment().year(currentYear).startOf('year');
    const end = moment().year(currentYear).endOf('year');
    
    onFilterChange({
      timeframe: {
        type: 'year',
        start: start.toISOString(),
        end: end.toISOString()
      },
      selectedCompanies: {},
      selectedCustomers: {},
      selectedVendors: {},
      selectedLocations: {}
    });
    setCustomDateRange({
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD')
    });
    setDateType('year');
    setSelectedPeriod(currentYear.toString());
  };

  return (
    <div className="analytics-filters">
      <div className="filters-header">
        <h3>Filters</h3>
        {(Object.keys(filterState.selectedCompanies).length > 0 ||
          Object.keys(filterState.selectedCustomers).length > 0 ||
          Object.keys(filterState.selectedVendors).length > 0 ||
          Object.keys(filterState.selectedLocations).length > 0) && (
          <button className="clear-filters" onClick={handleClearFilters}>
            Clear all
          </button>
        )}
      </div>

      <div className="date-filter-section">
        <select 
          className="date-type-select" 
          value={dateType} 
          onChange={handleDateTypeChange}
        >
          <option value="year">Year</option>
          <option value="quarter">Quarter</option>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="custom">Custom Range</option>
        </select>

        {dateType !== 'custom' && (
          <select 
            className="date-period-select"
            value={selectedPeriod || ''} 
            onChange={handlePeriodChange}
            disabled={!dateType || dateType === 'custom'}
          >
            <option value="">Select...</option>
            {getPeriodOptions().map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}

        {showCustomRange ? (
          <div className="custom-date-range">
            <input
              type="date"
              value={customDateRange.start}
              onChange={(e) => handleCustomDateChange('start', e.target.value)}
            />
            <input
              type="date"
              value={customDateRange.end}
              onChange={(e) => handleCustomDateChange('end', e.target.value)}
            />
          </div>
        ) : (
          <div className="date-range-text">
            {customDateRange.start && customDateRange.end ? (
              `${customDateRange.start} to ${customDateRange.end}`
            ) : (
              'No date range selected'
            )}
          </div>
        )}
      </div>

      <TreeFilter
        title="Company"
        items={availableFilters.companies}
        selectedItems={filterState.selectedCompanies}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedCompanies: newSelection })}
      />

      <TreeFilter
        title="Customer"
        items={availableFilters.customers}
        selectedItems={filterState.selectedCustomers}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedCustomers: newSelection })}
      />

      <TreeFilter
        title="Vendors"
        items={availableFilters.vendors}
        selectedItems={filterState.selectedVendors}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedVendors: newSelection })}
      />

      <TreeFilter
        title="Location"
        items={availableFilters.locations}
        selectedItems={filterState.selectedLocations}
        onSelectionChange={(newSelection) => onFilterChange({ ...filterState, selectedLocations: newSelection })}
      />
    </div>
  );
};

export default AnalyticsFilters;
