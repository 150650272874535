import React, { createContext, useContext, useReducer, useCallback, useMemo } from 'react';

const OrderStepContext = createContext();

const initialState = {
  isVisible: false,
  currentStep: 1,
  totalSteps: 4,
  orderData: {
    Jurisdiction: '',
    OrderID: '',
    OrderVendor: '',
    Buyer: '',
    OriginationNumber: '',
    DateOfDelivery: null,
    DateOfPayment: null,
    Comment: '',
    Advance: '0.00',
    Username: '',
    Status: 'Processing',
    itemInputType: 'file',
    orderItems: [], 
    currency: 'MK-MKD',
  },
  stepValidation: {
    1: false, // Basic Info
    2: false, // Company Details
    3: false, // Items
    4: false  // Documents
  }
};

function orderReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_ORDER_DATA':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          ...action.payload
        }
      };
    case 'SET_STEP':
      return {
        ...state,
        currentStep: action.payload,
        orderData: {
          ...state.orderData
        }
      };
    case 'UPDATE_VALIDATION':
      return {
        ...state,
        stepValidation: {
          ...state.stepValidation,
          [action.payload.step]: action.payload.isValid
        }
      };
    case 'ADD_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: [...state.orderData.orderItems, action.payload]
        }
      };
    case 'UPDATE_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: state.orderData.orderItems.map((item, index) =>
            index === action.payload.index ? action.payload.item : item
          )
        }
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: state.orderData.orderItems.filter((_, index) => index !== action.payload)
        }
      };
    case 'SET_DOCUMENT':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          documents: {
            ...state.orderData.documents,
            [action.payload.type]: action.payload.file
          }
        }
      };
    case 'SHOW_FORM':
      return {
        ...state,
        isVisible: true
      };
    case 'HIDE_FORM':
      return {
        ...initialState,
        isVisible: false
      };
    case 'INIT_ORDER':
      return {
        ...state,
        isVisible: true,
        orderData: {
          ...state.orderData,
          OrderID: action.payload.orderId,
          Username: action.payload.username,
          OrderVendor: action.payload.manufacturer
        }
      };
    case 'RESET_ORDER':
      return initialState;
    case 'CLEAR_ITEMS':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: []
        }
      };
    default:
      return state;
  }
}

export function OrderStepProvider({ children }) {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const nextStep = useCallback(() => {
    if (state.currentStep < state.totalSteps) {
      dispatch({ type: 'SET_STEP', payload: state.currentStep + 1 });
    }
  }, [state.currentStep, state.totalSteps]);

  const previousStep = useCallback(() => {
    if (state.currentStep > 1) {
      dispatch({ type: 'SET_STEP', payload: state.currentStep - 1 });
    }
  }, [state.currentStep]);

  const updateOrderData = useCallback((data) => {
    dispatch({ type: 'UPDATE_ORDER_DATA', payload: data });
  }, []);

  const setStepValidation = useCallback((step, isValid) => {
    dispatch({ 
      type: 'UPDATE_VALIDATION', 
      payload: { step, isValid }
    });
  }, []);

  const showForm = useCallback(() => dispatch({ type: 'SHOW_FORM' }), []);
  const hideForm = useCallback(() => dispatch({ type: 'HIDE_FORM' }), []);
  
  const initOrder = useCallback((orderId, username, manufacturer) => 
    dispatch({ 
      type: 'INIT_ORDER', 
      payload: { orderId, username, manufacturer } 
    }), []);

  const value = useMemo(() => ({
    state,
    dispatch,
    nextStep,
    previousStep,
    updateOrderData,
    setStepValidation,
    canProceed: (step) => state.stepValidation[step],
    isLastStep: () => state.currentStep === state.totalSteps,
    showForm,
    hideForm,
    initOrder,
  }), [state, dispatch, nextStep, previousStep, updateOrderData, setStepValidation, showForm, hideForm, initOrder]);

  return (
    <OrderStepContext.Provider value={value}>
      {children}
    </OrderStepContext.Provider>
  );
}

export function useOrderStep() {
  const context = useContext(OrderStepContext);
  if (!context) {
    throw new Error('useOrderStep must be used within an OrderStepProvider');
  }
  return context;
}
