import React from 'react';
import '../css/OfflineAlert.css';

const OfflineAlert = () => {
  return (
    <div className="offline-alert">
      <div className="offline-alert-box">
        <div className="offline-alert-icon">
          <i className="fas fa-exclamation"></i>
        </div>
        <div className="error-code">Error 503</div>
        <h2>Нема интернет конекција</h2>
        <p>Ве молиме проверете ја вашата интернет конекција и обидете се повторно.</p>
        <div className="offline-alert-details">
          <ul>
            <li>Проверете ја вашата WiFi или мобилна конекција</li>
            <li>Проверете дали други уреди имаат интернет</li>
            <li>Серверот е моментално недостапен</li>
          </ul>
        </div>
        <div className="offline-alert-status">
          <div className="offline-alert-dot"></div>
          <span>Се обидуваме да се поврземе...</span>
        </div>
      </div>
    </div>
  );
};

export default OfflineAlert;
