import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ref, query, orderByChild, startAt, endAt, onValue } from 'firebase/database';
import { db3 } from '../../firebase';
import moment from 'moment';
import './Analytics.css';
import AnalyticsFilters from './AnalyticsFilters';
import KPICard from './KPICard';
import CountryHeatmap from './CountryHeatmap';
import TopCompaniesChart from './TopCompaniesChart';
import PieChart from './PieChart';
import { FaChevronDown, FaChartBar } from 'react-icons/fa';
import { Spin } from 'antd';

const Analytics = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');
  const [graphsCollapsed, setGraphsCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [availableFilters, setAvailableFilters] = useState({
    companies: {
      'somitek-mk': {
        label: 'Somitek DOO Prilep',
        type: 'MK'
      },
      'somitek-eu': {
        label: 'Somitek EOOD',
        type: 'EU'
      }
    },
    customers: {},
    vendors: {},
    locations: {}
  });

  const [filterState, setFilterState] = useState({
    timeframe: {
      type: 'year',
      start: moment().startOf('year').toISOString(),
      end: moment().endOf('year').toISOString()
    },
    selectedCompanies: {},
    selectedCustomers: {},
    selectedVendors: {},
    selectedLocations: {},
  });

  const [customDateRange, setCustomDateRange] = useState({
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end: moment().endOf('year').format('YYYY-MM-DD')
  });

  const [invoiceData, setInvoiceData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Function to fetch data based on current filters
  const fetchFilteredData = useCallback(() => {
    setIsLoading(true);
    console.log('Fetching data with filters:', {
      timeframe: filterState.timeframe,
      selectedCompanies: Object.keys(filterState.selectedCompanies),
      selectedCustomers: Object.keys(filterState.selectedCustomers),
      selectedVendors: Object.keys(filterState.selectedVendors),
      selectedLocations: Object.keys(filterState.selectedLocations)
    });

    // Create a query reference with date constraints
    const dbRef = ref(db3, 'invoice_details');
    console.log('Database reference:', dbRef.toString());

    const startDate = filterState.timeframe.start;
    const endDate = filterState.timeframe.end;
    console.log('Date range:', { startDate, endDate });

    const dateQuery = query(
      dbRef,
      orderByChild('date_of_issue'),
      startAt(startDate),
      endAt(endDate)
    );
    console.log('Query created with path:', dateQuery.toString());

    onValue(dateQuery, (snapshot) => {
      console.log('Snapshot received, exists:', snapshot.exists());
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log('Raw snapshot value sample:', 
          Object.entries(data).slice(0, 2).map(([id, invoice]) => ({id, ...invoice}))
        );

        const allInvoices = Object.entries(data).map(([id, invoice]) => ({
          id,
          ...invoice,
          // Parse date strings to ensure proper comparison
          date_of_issue: invoice.date_of_issue !== 'NA' ? invoice.date_of_issue : null,
          date_of_payment_received: invoice.date_of_payment_received !== 'NA' ? invoice.date_of_payment_received : null
        }));
        console.log('Processed invoices count:', allInvoices.length);
        console.log('Sample processed invoice:', allInvoices[0]);

        setAllData(allInvoices);

        // Apply other filters
        let filtered = allInvoices;
        console.log('Initial filtered data count:', filtered.length);

        // Company filter
        if (Object.keys(filterState.selectedCompanies).length > 0) {
          filtered = filtered.filter(invoice => 
            filterState.selectedCompanies[invoice.company_uuid]
          );
          console.log('After company filter:', filtered.length);
        }

        // Customer filter
        if (Object.keys(filterState.selectedCustomers).length > 0) {
          filtered = filtered.filter(invoice => 
            filterState.selectedCustomers[invoice.company_uuid]
          );
          console.log('After customer filter:', filtered.length);
        }

        // Vendor filter
        if (Object.keys(filterState.selectedVendors).length > 0) {
          filtered = filtered.filter(invoice => 
            filterState.selectedVendors[invoice.vendor]
          );
          console.log('After vendor filter:', filtered.length);
        }

        // Location filter
        if (Object.keys(filterState.selectedLocations).length > 0) {
          filtered = filtered.filter(invoice => 
            filterState.selectedLocations[invoice.location_of_company]
          );
          console.log('After location filter:', filtered.length);
        }

        setFilteredData(filtered);
        setInvoiceData(filtered);
        console.log('Final filtered data count:', filtered.length);

        // Update available filters based on filtered data
        const newAvailableFilters = {
          companies: {},
          customers: {},
          vendors: {},
          locations: {}
        };

        filtered.forEach(invoice => {
          if (invoice.company_uuid && invoice.company_name) {
            newAvailableFilters.companies[invoice.company_uuid] = {
              label: invoice.company_name,
              type: invoice.location_of_company || ''
            };
          }
          if (invoice.company_uuid && invoice.company_name) {
            newAvailableFilters.customers[invoice.company_uuid] = {
              label: invoice.company_name,
              type: invoice.location_of_company || ''
            };
          }
          if (invoice.vendor && invoice.vendor !== 'Unknown') {
            newAvailableFilters.vendors[invoice.vendor] = {
              label: invoice.vendor,
              type: ''
            };
          }
          if (invoice.location_of_company && invoice.location_of_company !== 'Unknown') {
            newAvailableFilters.locations[invoice.location_of_company] = {
              label: invoice.location_of_company,
              type: ''
            };
          }
        });

        console.log('New available filters:', newAvailableFilters);
        setAvailableFilters(newAvailableFilters);
      } else {
        console.log('No data found for the current filters');
        console.log('Query path:', dbRef.toString());
        console.log('Date range:', { startDate, endDate });
        setAllData([]);
        setFilteredData([]);
        setInvoiceData([]);
      }
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    });
  }, [filterState]);

  // Fetch data when component mounts and when filters change
  useEffect(() => {
    fetchFilteredData();
  }, [fetchFilteredData]);

  const handleFilterChange = (newFilterState) => {
    console.log('Filter state changed:', newFilterState);
    setFilterState(newFilterState);
  };

  const handleCountryClick = (country) => {
    setFilterState(prev => ({
      ...prev,
      selectedLocations: {
        [country]: { label: country }
      }
    }));
  };

  const handleCompanyClick = (companyName) => {
    const company = Object.entries(availableFilters.companies).find(([key, value]) => 
      value.label === companyName
    );

    if (company) {
      setFilterState(prev => ({
        ...prev,
        selectedCompanies: {
          [company[0]]: company[1]
        }
      }));
    }
  };

  const handleChartClick = (type, value) => {
    if (!value) return;
    
    setFilterState(prev => {
      const newState = { ...prev };
      
      switch (type) {
        case 'vendor':
          newState.selectedVendors = {
            ...prev.selectedVendors,
            [value]: !prev.selectedVendors[value]
          };
          break;
        case 'company':
          newState.selectedCustomers = {
            ...prev.selectedCustomers,
            [value]: !prev.selectedCustomers[value]
          };
          break;
        case 'location':
          newState.selectedLocations = {
            ...prev.selectedLocations,
            [value]: !prev.selectedLocations[value]
          };
          break;
        default:
          break;
      }
      
      return newState;
    });
  };

  const calculatePreviousPeriodDates = (timeframe) => {
    const now = new Date();
    let currentStart = new Date();
    let currentEnd = now;
    let previousStart = new Date();
    let previousEnd = new Date();

    switch (timeframe) {
      case 'week':
        currentStart.setDate(now.getDate() - 7);
        previousStart.setDate(now.getDate() - 14);
        previousEnd.setDate(now.getDate() - 7);
        break;
      case 'month':
        currentStart.setMonth(now.getMonth() - 1);
        previousStart.setMonth(now.getMonth() - 2);
        previousEnd.setMonth(now.getMonth() - 1);
        break;
      case 'quarter':
        currentStart.setMonth(now.getMonth() - 3);
        previousStart.setMonth(now.getMonth() - 6);
        previousEnd.setMonth(now.getMonth() - 3);
        break;
      case 'year':
        currentStart.setFullYear(now.getFullYear() - 1);
        previousStart.setFullYear(now.getFullYear() - 2);
        previousEnd.setFullYear(now.getFullYear() - 1);
        break;
      default:
        return null;
    }

    return {
      current: { start: currentStart, end: currentEnd },
      previous: { start: previousStart, end: previousEnd }
    };
  };

  const applyDateFilter = (data, timeframe, customRange) => {
    const now = new Date();
    let startDate;
    let endDate = now;

    if (timeframe === 'custom' && customRange.start && customRange.end) {
      startDate = new Date(customRange.start);
      endDate = new Date(customRange.end);
    } else {
      switch (timeframe) {
        case 'week':
          startDate = new Date(now);
          startDate.setDate(now.getDate() - 7);
          break;
        case 'month':
          startDate = new Date(now);
          startDate.setMonth(now.getMonth() - 1);
          break;
        case 'quarter':
          startDate = new Date(now);
          startDate.setMonth(now.getMonth() - 3);
          break;
        case 'year':
          startDate = new Date(now);
          startDate.setFullYear(now.getFullYear() - 1);
          break;
        default:
          startDate = new Date(0); // Beginning of time
      }
    }

    return data.filter(invoice => {
      const invoiceDate = new Date(invoice.date_of_issue);
      return invoiceDate >= startDate && invoiceDate <= endDate;
    });
  };

  const applyFilters = useCallback((data, filters) => {
    // First apply date filter
    let filteredByDate = applyDateFilter(data, filters.timeframe, customDateRange);

    // Then apply other filters
    const filtered = filteredByDate.filter(item => {
      const companyFilters = Object.keys(filters.selectedCompanies);
      const customerFilters = Object.keys(filters.selectedCustomers);
      const vendorFilters = Object.keys(filters.selectedVendors);
      const locationFilters = Object.keys(filters.selectedLocations);

      const companyMatch = companyFilters.length === 0 || 
        (item.company_uuid && companyFilters.includes(item.company_uuid));
      
      const customerMatch = customerFilters.length === 0 || 
        (item.company_uuid && customerFilters.includes(item.company_uuid));
      
      const vendorMatch = vendorFilters.length === 0 || 
        (item.vendor && vendorFilters.includes(item.vendor));
      
      const locationMatch = locationFilters.length === 0 || 
        (item.location_of_company && locationFilters.includes(item.location_of_company));

      return companyMatch && customerMatch && vendorMatch && locationMatch;
    });

    setFilteredData(filtered);
  }, [customDateRange]);

  useEffect(() => {
    const fetchData = async () => {
      const invoicesRef = ref(db3, 'invoice_details');
      onValue(invoicesRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const invoices = Object.entries(data).map(([id, invoice]) => ({
            id,
            ...invoice,
            date: invoice.date_of_issue,
            paid_date: invoice.date_of_payment_received
          }));
          setAllData(invoices);
          applyFilters(invoices, filterState);
        } else {
          console.log('No invoice data available');
          setAllData([]);
          setFilteredData([]);
        }
      });
    };
    fetchData();
  }, []); // Only fetch once on component mount

  // Effect to update filters when allData changes
  useEffect(() => {
    if (!allData.length) return;

    const newFilters = {
      companies: availableFilters.companies,
      customers: {},
      vendors: {},
      locations: {}
    };

    // Apply date filter first to get relevant data for filter options
    const dateFilteredData = applyDateFilter(allData, filterState.timeframe, customDateRange);

    dateFilteredData.forEach(inv => {
      if (inv.company_uuid) {
        newFilters.customers[inv.company_uuid] = {
          label: inv.company_name
        };
      }

      if (inv.vendor) {
        newFilters.vendors[inv.vendor] = {
          label: inv.vendor
        };
      }

      if (inv.location_of_company) {
        newFilters.locations[inv.location_of_company] = {
          label: inv.location_of_company
        };
      }
    });

    setAvailableFilters(newFilters);
  }, [allData, filterState.timeframe, customDateRange]);

  // Effect to update filtered data when filters change
  useEffect(() => {
    applyFilters(allData, filterState);
  }, [filterState, customDateRange, allData, applyFilters]);

  const handleResize = useCallback(() => {
    if (contentRef.current && isExpanded) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(`${height}px`);
    } else {
      setContentHeight('0');
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      // Use RAF to ensure DOM is updated
      requestAnimationFrame(() => {
        if (contentRef.current) {
          const height = contentRef.current.scrollHeight;
          setContentHeight(`${height}px`);
        }
      });
    } else {
      setContentHeight('0');
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isExpanded]);

  const toggleAnalytics = () => {
    setIsExpanded(!isExpanded);
  };

  const isSingleCustomerSelected = Object.keys(filterState.selectedCustomers).length === 1;

  return (
    <div className="analytics-container">
      <div className="analytics-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="header-content">
          <FaChartBar className="header-icon" />
          <h2>Analytics</h2>
        </div>
        <FaChevronDown className={`chevron ${isExpanded ? 'expanded' : ''}`} />
      </div>

      <div className={`analytics-content ${isExpanded ? 'expanded' : ''}`}>
        <div className="analytics-body">
          <div className="analytics-sidebar">
            <AnalyticsFilters
              availableFilters={availableFilters}
              filterState={filterState}
              onFilterChange={handleFilterChange}
              customDateRange={customDateRange}
              setCustomDateRange={setCustomDateRange}
            />
          </div>

          <div className="analytics-main">
            {isLoading ? (
              <div className="loading-container">
                <Spin size="large" />
                <p>Loading analytics data...</p>
              </div>
            ) : (
              <>
                <div className="kpi-section">
                  <div className="kpi-cards">
                    <KPICard
                      title="Total Sales (MKD)"
                      data={filteredData}
                      metric="salesMKD"
                      currency="MKD"
                    />
                    <KPICard
                      title="Total Sales (EUR)"
                      data={filteredData}
                      metric="salesEUR"
                      currency="EUR"
                    />
                    <KPICard
                      title="Total Invoices"
                      data={filteredData}
                      metric="count"
                    />
                    <KPICard
                      title="Average Payment Latency"
                      data={filteredData}
                      metric="latency"
                      unit="days"
                      inverseColors
                    />
                  </div>
                </div>
                <div className="charts-grid">
                  <div className="chart-section large">
                    <CountryHeatmap 
                      data={filteredData}
                      onCountryClick={(country) => handleChartClick('location', country)}
                    />
                  </div>
                  <div className="chart-section">
                    <h3>Income by Country (EUR)</h3>
                    <PieChart 
                      data={filteredData}
                      metric="incomeEUR"
                      onItemClick={(name) => handleChartClick('location', name)}
                    />
                  </div>

                  <div className="chart-section">
                    <h3>Companies with Highest Payment Latency</h3>
                    <TopCompaniesChart 
                      data={filteredData}
                      metric="latency"
                      unit="days"
                      onItemClick={(name) => handleChartClick('company', name)}
                    />
                  </div>
                  <div className="chart-section">
                    <h3>Top Companies by Income</h3>
                    <TopCompaniesChart 
                      data={filteredData}
                      metric="income"
                      unit="EUR"
                      onItemClick={(name) => handleChartClick('company', name)}
                    />
                  </div>
                  <div className="chart-section">
                    <h3>Orders by Vendor</h3>
                    <PieChart 
                      data={filteredData}
                      metric="vendorOrders"
                      onItemClick={(name) => handleChartClick('vendor', name)}
                    />
                  </div>
                  <div className="chart-section">
                    <h3>Income by Vendor</h3>
                    <PieChart 
                      data={filteredData}
                      metric="vendorIncome"
                      onItemClick={(name) => handleChartClick('vendor', name)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
