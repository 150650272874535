import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { db2, ref, get } from '../../firebase';
import { StepBase } from './StepBase';
import { useOrderStep } from '../../context/OrderStepContext';
import '../../css/editOrderForm.css';

// Helper function to parse DD-MM-YYYY to Date object
const parseDate = (dateStr) => {
  if (!dateStr) return null;
  if (dateStr instanceof Date) return dateStr;
  const [day, month, year] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return isNaN(date.getTime()) ? null : date;
};

export function CompanyDetailsStep({ companies, userRole }) {
  const { state, updateOrderData, setStepValidation } = useOrderStep();
  const { orderData } = state;

  const [selectedCompany, setSelectedCompany] = useState(orderData.Buyer || null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  // Fetch companies from db2
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db2, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const companiesData = snapshot.val();
          const companiesArray = Object.entries(companiesData).map(([id, data]) => ({
            id,
            ...data
          }));
          setAllCompanies(companiesArray);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);
// First, define the character mappings outside the useMemo
const latinToCyrillic = {
  'a': 'а', 'b': 'б', 'v': 'в', 'g': 'г', 'd': 'д', 'đ': 'ђ', 'e': 'е',
  'ž': 'ж', 'z': 'з', 'i': 'и', 'j': 'ј', 'k': 'к', 'l': 'л', 'lj': 'љ',
  'm': 'м', 'n': 'н', 'nj': 'њ', 'o': 'о', 'p': 'п', 'r': 'р', 's': 'с',
  't': 'т', 'ć': 'ћ', 'u': 'у', 'f': 'ф', 'h': 'х', 'c': 'ц', 'č': 'ч',
  'dž': 'џ', 'š': 'ш'
};

const cyrillicToLatin = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'ђ': 'đ', 'е': 'e',
  'ж': 'ž', 'з': 'z', 'и': 'i', 'ј': 'j', 'к': 'k', 'л': 'l', 'љ': 'lj',
  'м': 'm', 'н': 'n', 'њ': 'nj', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's',
  'т': 't', 'ћ': 'ć', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'č',
  'џ': 'dž', 'ш': 'š'
};

const filteredCompaniesResult = useMemo(() => {
  const normalizeText = (text) => {
    if (!text) return ['', ''];
    
    // Convert to lowercase and normalize diacritics
    text = text.toLowerCase().normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
    
    // Create both Latin and Cyrillic versions
    let latinVersion = text;
    let cyrillicVersion = text;

    // Convert to Latin
    Object.entries(cyrillicToLatin).forEach(([cyr, lat]) => {
      cyrillicVersion = cyrillicVersion.replace(new RegExp(cyr, 'g'), lat);
    });

    // Convert to Cyrillic
    Object.entries(latinToCyrillic).forEach(([lat, cyr]) => {
      latinVersion = latinVersion.replace(new RegExp(lat, 'g'), cyr);
    });

    // Normalize special cases
    cyrillicVersion = cyrillicVersion
      .replace(/đ/g, 'dj')
      .replace(/ć/g, 'c')
      .replace(/č/g, 'c')
      .replace(/š/g, 's')
      .replace(/ž/g, 'z')
      .replace(/dž/g, 'dz');

    return [cyrillicVersion, latinVersion];
  };

  if (companySearch.trim() === '') {
    return allCompanies.slice(0, 5);
  } else {
    const searchTerms = normalizeText(companySearch);
    
    return allCompanies
      .filter(company => {
        const [cyrillicName, latinName] = normalizeText(company.ime);
        const [cyrillicCity, latinCity] = normalizeText(company.grad_ime);
        const [cyrillicCountry, latinCountry] = normalizeText(company.drzava_ime);
        
        return searchTerms.some(searchTerm => 
          cyrillicName?.includes(searchTerm) || latinName?.includes(searchTerm) ||
          cyrillicCity?.includes(searchTerm) || latinCity?.includes(searchTerm) ||
          cyrillicCountry?.includes(searchTerm) || latinCountry?.includes(searchTerm)
        );
      })
      .slice(0, 5);
  }
}, [companySearch, allCompanies]);

  // Update filtered companies when the memoized result changes
  useEffect(() => {
    setFilteredCompanies(filteredCompaniesResult);
  }, [filteredCompaniesResult]);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company.ime);
    updateOrderData({
      Buyer: company.ime,
      BuyerID: company.id
    });
    setShowCompanyModal(false);
    setCompanySearch('');
  };

  const openCompaniesInNewTab = (e) => {
    e.preventDefault(); // Prevent any default button behavior
    e.stopPropagation(); // Stop event from bubbling up
    window.open('/companies?openModal=true', '_blank');
  };

  // Initialize dates when component mounts
  useEffect(() => {
    const updatedData = {};
    let hasUpdates = false;

    if (orderData.DateOfDelivery && typeof orderData.DateOfDelivery === 'string') {
      updatedData.DateOfDelivery = parseDate(orderData.DateOfDelivery);
      hasUpdates = true;
    }
    if (orderData.DateOfPayment && typeof orderData.DateOfPayment === 'string') {
      updatedData.DateOfPayment = parseDate(orderData.DateOfPayment);
      hasUpdates = true;
    }

    if (hasUpdates) {
      updateOrderData(updatedData);
    }
  }, [orderData.DateOfDelivery, orderData.DateOfPayment, updateOrderData]);

  const handleChange = (e) => {
    updateOrderData({ [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, field) => {
    updateOrderData({ [field]: date });
  };

  // Memoize the validation check
  const isValid = useMemo(() => !!orderData.Buyer, [orderData.Buyer]);

  // Update step validation when validity changes
  useEffect(() => {
    setStepValidation(2, isValid);
  }, [isValid, setStepValidation]);

  return (
    <StepBase stepNumber={2} title="Информации за нарачка">
      <div className='step-sub-content'>
      <div className="form-group">
        <label className="label">Купец:</label>
        <div className="buyer-display">
          <div className="buyer-text">
            {selectedCompany || 'Изберете купец'}
          </div>
          <button 
            type="button"
            className="edit-button"
            onClick={() => setShowCompanyModal(true)}
          >
            ✏️
          </button>
        </div>
      </div>

      {showCompanyModal && (
        <>
          <div className="company-modal-overlay" onClick={() => setShowCompanyModal(false)} />
          <div className="company-modal">
            <div className="modal-title">Изберете купец</div>
            <div className="company-search">
              <input
                type="text"
                placeholder="Пребарувај купци..."
                value={companySearch}
                onChange={(e) => setCompanySearch(e.target.value)}
                autoFocus
              />
            </div>
            <div className="company-list">
              {filteredCompanies.map((company) => (
                <div
                  key={company.id}
                  className="company-item"
                  onClick={() => handleCompanySelect(company)}
                >
                  <div className="company-name">{company.ime}</div>
                  <div className="company-country">
                    {company.grad_ime && company.drzava_ime 
                      ? `${company.grad_ime}, ${company.drzava_ime}`
                      : company.drzava_ime || company.grad_ime || 'Location not specified'}
                  </div>
                </div>
              ))}
            </div>
            <div className="company-modal-footer">
              <button
                className="btn btn-primary"
                onClick={openCompaniesInNewTab}
              >
                Додај нова фирма
              </button>
            </div>
          </div>
        </>
      )}

      <div className="form-row">
        <div className="form-group">
          <label className="label">ОА Број:</label>
          <input
            type="text"
            name="OriginationNumber"
            value={orderData.OriginationNumber || ''}
            onChange={handleChange}
            className="input"
          />
        </div>

        {userRole === "admin" && (
          <>
            <div className="form-group">
              <label className="label">Датум на достава:</label>
              <DatePicker
                selected={parseDate(orderData.DateOfDelivery)}
                onChange={(date) => handleDateChange(date, 'DateOfDelivery')}
                dateFormat="dd-MM-yyyy"
                className="input"
              />
            </div>
            <div className="form-group">
              <label className="label">Датум на уплата:</label>
              <DatePicker
                selected={parseDate(orderData.DateOfPayment)}
                onChange={(date) => handleDateChange(date, 'DateOfPayment')}
                dateFormat="dd-MM-yyyy"
                className="input"
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label className="label">Коментар:</label>
          <textarea
            name="Comment"
            value={orderData.Comment || ''}
            onChange={handleChange}
            className="form-control"
            rows="4"
            style={{ resize: 'vertical' }}
          />
        </div>

        <div className="form-group">
          <label className="label">Аванс (во Валута):</label>
          <input
            type="text"
            name="Advance"
            value={orderData.Advance || ''}
            onChange={handleChange}
            className="input"
          />
        </div>
        <div className="form-group">
            <label className="label">Валута:</label>
            <select
              type="text"
              name="currency"
              value={orderData.currency}
              onChange={handleChange}
              className="input"
            >
              <option value="MK-MKD">Фирма:MK, Валута:MKD</option>
              <option value="MK-EUR">Фирма:MK, Валута:€</option>
              <option value="EU-EUR">Фирма:ЕУ, Валута:€</option>
              </select>
          </div>
      </div>
      </div>
    </StepBase>
  );
}
