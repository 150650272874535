import { ORDER_STATUS_TO_PAYMENT_STATUS } from './analyticsDb';

// Map of country names to ISO codes
const countryNameToCode = {
  'Србија': 'RS',
  'Македонија': 'MK',
  'Хрватска': 'HR',
  'Словенија': 'SI',
  'Босна и Херцеговина': 'BA',
  'Црна Гора': 'ME',
  'Албанија': 'AL',
  'Бугарија': 'BG',
  'Косово': 'XK',
  'Грција': 'GR',
  'Романија': 'RO',
  // English variants
  'Serbia': 'RS',
  'Macedonia': 'MK',
  'Croatia': 'HR',
  'Slovenia': 'SI',
  'Bosnia and Herzegovina': 'BA',
  'Montenegro': 'ME',
  'Albania': 'AL',
  'Bulgaria': 'BG',
  'Kosovo': 'XK',
  'Greece': 'GR',
  'Romania': 'RO',
  // With emoji variants
  'Srbija 🇷🇸': 'RS',
  'Makedonija 🇲🇰': 'MK',
  'Hrvatska 🇭🇷': 'HR',
  'Slovenija 🇸🇮': 'SI',
  'Bosna i Hercegovina 🇧🇦': 'BA',
  'Crna Gora 🇲🇪': 'ME',
  'Albanija 🇦🇱': 'AL',
  'Bugarija 🇧🇬': 'BG',
  'Kosovo 🇽🇰': 'XK',
  'Grcija 🇬🇷': 'GR',
  'Romanija 🇷🇴': 'RO'
};

// Convert 2-letter country code to 3-letter code
const countryCode2to3 = {
  'RS': 'SRB',  // Serbia
  'UA': 'UKR',  // Ukraine
  'CH': 'CHE',  // Switzerland
  'GH': 'GHA',  // Ghana
  'CZ': 'CZE',  // Czech Republic
  'SK': 'SVK',  // Slovakia
  'AE': 'ARE',  // UAE
  'DE': 'DEU',  // Germany
  'GR': 'GRC',  // Greece
  'MK': 'MKD',  // North Macedonia
  'HR': 'HRV',  // Croatia
  'XK': 'XKX',  // Kosovo
  'SI': 'SVN',  // Slovenia
  'BG': 'BGR',  // Bulgaria
  'AL': 'ALB',  // Albania
  'BA': 'BIH',  // Bosnia and Herzegovina
  'ME': 'MNE',  // Montenegro
  'RO': 'ROU',  // Romania
  'AT': 'AUT',  // Austria
  'IT': 'ITA',  // Italy
  'HU': 'HUN',  // Hungary
  'TR': 'TUR',  // Turkey
  'PL': 'POL',  // Poland
  'MD': 'MDA',  // Moldova
  'BY': 'BLR',  // Belarus
  'LT': 'LTU',  // Lithuania
  'LV': 'LVA',  // Latvia
  'EE': 'EST',  // Estonia
  'FI': 'FIN',  // Finland
  'SE': 'SWE',  // Sweden
  'NO': 'NOR',  // Norway
  'DK': 'DNK',  // Denmark
  'GB': 'GBR',  // United Kingdom
  'IE': 'IRL',  // Ireland
  'FR': 'FRA',  // France
  'ES': 'ESP',  // Spain
  'PT': 'PRT',  // Portugal
  'NL': 'NLD',  // Netherlands
  'BE': 'BEL',  // Belgium
  'LU': 'LUX',  // Luxembourg
};

// Extract country code from country name
export const extractCountryCode = (countryName) => {
  if (!countryName) {
    console.error('No country name provided');
    return null;
  }

  console.log('Extracting country code from:', countryName);

  // Try direct lookup first
  const code2 = countryNameToCode[countryName];
  if (code2) {
    console.log('Found direct match with code:', code2);
    return countryCode2to3[code2];
  }

  // Try without emoji
  const nameWithoutEmoji = countryName.replace(/[\u{1F1E6}-\u{1F1FF}]/gu, '').trim();
  console.log('Trying without emoji:', nameWithoutEmoji);
  const code2WithoutEmoji = countryNameToCode[nameWithoutEmoji];
  if (code2WithoutEmoji) {
    console.log('Found match without emoji with code:', code2WithoutEmoji);
    return countryCode2to3[code2WithoutEmoji];
  }

  // Try extracting from emoji
  const emojiMatch = countryName.match(/[\u{1F1E6}-\u{1F1FF}]{2}/u);
  if (emojiMatch) {
    const emoji = emojiMatch[0];
    const codePoints = [...emoji].map(char => char.codePointAt(0));
    const firstLetter = String.fromCharCode(codePoints[0] - 0x1F1E6 + 65);
    const secondLetter = String.fromCharCode(codePoints[1] - 0x1F1E6 + 65);
    const code2FromEmoji = firstLetter + secondLetter;
    console.log('Extracted code from emoji:', code2FromEmoji);
    return countryCode2to3[code2FromEmoji];
  }

  console.error('Could not find country code for:', countryName);
  return null;
};

// Format invoice ID based on type
export const formatInvoiceId = (id, type = 'standard') => {
  if (type === 'uuid') return id;
  
  // For standard invoice numbers (e.g., "001\21")
  const cleanId = id.replace(/\\/g, '\\\\');
  return cleanId;
};

// Prepare invoice data for analytics DB
export const prepareInvoiceData = (order, company) => {
  console.log('Preparing invoice data for order:', order);
  console.log('With company:', company);

  if (!order || !company) {
    console.error('Missing order or company data');
    console.log('Order:', order);
    console.log('Company:', company);
    return null;
  }

  const countryCode = extractCountryCode(company.drzava_ime);
  console.log('Extracted country code:', countryCode, 'from:', company.drzava_ime);
  
  if (!countryCode) {
    console.error('Could not extract country code from:', company.drzava_ime);
    return null;
  }

  // Convert totalSum to number if it's a string
  let amount = 0;
  if (order.totalSum) {
    // Remove any commas and convert to number
    amount = Number(order.totalSum.replace(/,/g, ''));
    console.log('Converted amount:', amount, 'from:', order.totalSum);
  }

  const invoiceData = {
    id: order.id,
    amount: amount,
    company_name: company.ime,
    company_uuid: company.id,
    currency: order.currency || 'MK-MKD',
    date_of_issue: order.CreatedAt || new Date().toISOString(),
    date_of_payment_received: order.DateOfPayment || null,
    location_of_company: countryCode,
    payment_status: ORDER_STATUS_TO_PAYMENT_STATUS[order.Status] || 'Unpaid',
    vendor: order.OrderVendor || 'Other',
    invoice_number: order.invoiceNumber || order.id
  };

  console.log('Prepared invoice data:', invoiceData);
  return invoiceData;
};

// Generate invoice ID for new invoices
export const generateInvoiceId = (type = 'standard') => {
  if (type === 'uuid') {
    return crypto.randomUUID();
  }
  
  // For standard invoice numbers, you'll need to implement your numbering logic
  // This is just a placeholder
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${month}\\${year}`;
};
