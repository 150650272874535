/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/debtorsTable.css';
import { ref, get, update } from 'firebase/database';
import { db, db2 } from '../firebase.js';
import EditOrderForm from './EditOrderForm.js';
import ConfirmationModal from './ConfirmationModal';
import CompanyInfoModal from './CompanyInfoModal';
import { updateInvoiceStatusInAnalytics } from '../utils/analyticsDb';

// Helper function to format date for main db (DD-MM-YYYY)
const formatDateForMainDb = (date) => {
  if (typeof date === 'string') {
    // If it's already a string, try to parse it
    date = new Date(date);
  }
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const DebtorsTable = ({ user, userRole, orders, vendor, onClose }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [showOutstandingDebt, setShowOutstandingDebt] = useState(true);

  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const handleArchiveOrder = async (order) => {
    try {
      console.log('Preparing to archive order:', order);
      setSelectedOrder(order);
      setShowConfirmationModal(true);
    } catch (error) {
      console.error('Error preparing to archive order:', error);
    }
  };

  const handleOpenCompanyInfo = async (buyerName, BuyerID) => {
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => (BuyerID ? company.id === BuyerID : company.ime === buyerName));
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Company not found');
        }
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  const handleConfirmArchive = async () => {
    if (selectedOrder) {
      try {
        console.log('Archiving order:', selectedOrder);
        const orderId = selectedOrder.id || selectedOrder.firebaseID || selectedOrder.OrderID || selectedOrder.OrdderID;
        
        if (!orderId) {
          throw new Error('Order ID not found');
        }

        const now = new Date();
        const mainDbDate = formatDateForMainDb(now);
        const analyticsDbDate = now.toISOString();

        // Create a reference to the specific order's path
        const orderRef = ref(db, `orders/${orderId}`);
        console.log('Updating order status at path:', `orders/${orderId}`);
        
        // Update the Status attribute to "Archived" and set date in DD-MM-YYYY format
        await update(orderRef, {
          Status: 'Archived',
          DateOfPayment: mainDbDate
        });
        console.log('Order status updated in main DB');
        
        // Update status in analytics DB with ISO date
        console.log('Updating status in analytics DB');
        await updateInvoiceStatusInAnalytics(
          orderId,
          'Archived',
          analyticsDbDate
        );
        console.log('Analytics DB updated successfully');

        alert('Order archived successfully');
      } catch (error) {
        console.error('Error updating order status:', error);
        alert('Error archiving order: ' + error.message);
      } finally {
        // Close the modal and reset the selected order
        setShowConfirmationModal(false);
        setSelectedOrder(null);
      }
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
    setSelectedOrder(null);
  };

  // Function to close preview window
  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  // Function to parse date from DD-MM-YYYY string
  const parseDate = (dateStr) => {
    if (!dateStr) return new Date(); // Return current date if dateStr is undefined
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are 0-based in JavaScript
  };

  // Function to get the background class based on due date
  const getDueDateClass = (dueDate) => {
    if (!dueDate) return ''; // Return empty string for undefined due dates
    const today = new Date();
    const due = parseDate(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 7) return 'purple';
    if (diffDays < 15) return 'pale-orange';
    if (diffDays < 30) return 'pale-yellow';
    if (diffDays < 60) return 'pale-green';
    if (diffDays < 90) return 'pale-blue';
    if (diffDays < 120) return 'gray';
    return '';
  };

  // Function to parse a string formatted number (XXX,XXX.XX) to a float
  const parseFormattedNumber = (numberStr) => {
    return parseFloat(numberStr.replace(/,/g, ''));
  };

  // Function to format a number to the string format XXX,XXX.XX
  const formatNumber = (number) => {
    return new Intl.NumberFormat('mk-MK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Determine the currency suffix based on vendor
  const getCurrencySuffix = () => {
    switch (vendor) {
      case 'MK-Denari':
        return 'МКД';
      case 'MK-EUR':
      case 'EU':
        return '€';
      default:
        return '';
    }
  };

  const calculateDebt = (order) => {
    const totalSum = parseFormattedNumber(order.totalSum) || 0;
    const advance = parseFormattedNumber(order.Advance);
    
    // Return total sum if showing total debt
    if (!showOutstandingDebt) {
      return totalSum;
    }
    
    // Return total sum - advance, but only if advance is a valid number
    return totalSum - (isNaN(advance) ? 0 : advance);
  };

  const calculateTotalSum = () => {
    return Object.values(orders).reduce((sum, order) => {
      return sum + calculateDebt(order);
    }, 0);
  };

  // Print function
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      const printDate = new Date().toLocaleString('en-GB', { timeZone: 'UTC' });
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Orders</title>
            <style>
              /* Add any specific styles for printing here */
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            <h1>${vendor}</h1>
            <p>Извод издаден ${printDate}}</p>
            <table>
              <thead>
                <tr>
                  <th>Број на нарачка</th>
                  <th>Фактура</th>
                  <th>Купец</th>
                  <th>Сума</th>
                  <th>Датум Наплата</th>
                </tr>
              </thead>
              <tbody>
                ${Object.keys(orders).map((firebaseId) => {
                  const order = orders[firebaseId];
                  const rowClass = getDueDateClass(order.dueDate);
                  return `
                    <tr class="${rowClass}">
                      <td>${order.OrderID}</td>
                      <td>${order.invoiceNumber}</td>
                      <td>${order.Buyer}</td>
                      <td>${formatNumber(parseFormattedNumber(order.totalSum) - parseFormattedNumber(order.Advance)  || 0)}</td>
                      <td>${order.dueDate}</td>
                    </tr>
                  `;
                }).join('')}
                <tr>
                  <td colspan="3"><strong>Вкупно:</strong></td>
                  <td><strong>${formatNumber(calculateTotalSum())} ${getCurrencySuffix()}</strong></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error('Failed to open print window.');
    }
  };

  return (
    <div className="orders-table-container">
      <div className="header-container">
        <h2>{vendor}</h2>
        <div className="debt-toggle">
          <span>Вкупна Сума</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={showOutstandingDebt}
              onChange={(e) => setShowOutstandingDebt(e.target.checked)}
            />
            <span className="slider"></span>
          </label>
          <span>Преостанат Долг</span>
        </div>
        <button className="print-button" onClick={handlePrint}>Печати</button>
        <button onClick={onClose} className="close-button">×</button>
      </div>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-3">
        <thead>
          <tr>
            <th>Број на<br />Нарачка</th>
            <th>Датум на<br />Нарачка</th>
            <th>Број на<br />Фактура</th>
            <th>Име на<br />Фирма</th>
            <th>{showOutstandingDebt ? 'Преостанат Долг' : 'Вредност на Фактура'}</th>
            <th>Рок на<br />Плаќање</th>
            {userRole === "admin" && <th>Подеси</th>}
          </tr>
        </thead>
        {Object.keys(orders).length > 0 ? (
          <tbody>
            {Object.keys(orders).map((firebaseId) => {
              const order = orders[firebaseId];
              const rowClass = getDueDateClass(order.dueDate);
              return (
                <tr key={firebaseId} className={rowClass}>
                  {userRole === "admin" ? (
                    <td>
                      <a className='order-link' href="#" onClick={() => handleEditOrder(order)}>
                        {order.OrderID}
                      </a>
                    </td>
                  ) : (
                    <td>{order.OrderID}</td>
                  )}
                  <td>{order.orderDate}</td>
                  <td>{order.invoiceNumber}</td>
                  <td>
                    <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer, order.BuyerID)}>
                      {order.Buyer}
                    </a>
                  </td> 
                  <td className='total-sum-number-invoice'>{formatNumber(calculateDebt(order))}</td>
                  <td>{order.dueDate}</td>
                  {userRole === "admin" && (
                    <td>
                      <button onClick={() => handleArchiveOrder(order)}>Платено</button>
                    </td>
                  )}
                </tr>
              );
            })}
            <tr>
              <td colSpan="4"><strong>Вкупно:</strong></td>
              <td><strong>{formatNumber(calculateTotalSum())} {getCurrencySuffix()}</strong></td>
              <td></td>
              {userRole === "admin" && <td></td>}
            </tr>
          </tbody>
        ) : (
          <p>Нема Активни</p>
        )}
      </table>
      {editingOrder && (
        <EditOrderForm
        userRole={userRole}
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Дали сте сигурни дека сакате да го промените статусот на Архивиран?"
          onConfirm={handleConfirmArchive}
          onCancel={handleCancelConfirmation}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default DebtorsTable;
