import { db3, db2 } from '../firebase';
import { ref, get, set, update } from 'firebase/database';
import { prepareInvoiceData } from './countryUtils';

// Payment status mapping
export const ORDER_STATUS_TO_PAYMENT_STATUS = {
  'Processing': 'Unpaid',
  'Invoiced': 'Unpaid',
  'Archived': 'Paid'
};

// Debug logging function
const logDbOperation = (operation, path, data) => {
  console.log(`[DB3 ${operation}] Path: ${path}`);
  console.log('Data:', JSON.stringify(data, null, 2));
};

// Helper function to parse DD-MM-YYYY format
const parseDateString = (dateStr) => {
  if (!dateStr) return null;
  
  // If it's already in ISO format, return a new Date
  if (dateStr.includes('T')) {
    return new Date(dateStr);
  }

  // Parse DD-MM-YYYY format
  const [day, month, year] = dateStr.split('-').map(Number);
  if (!day || !month || !year) return null;
  
  // Month is 0-based in Date constructor
  return new Date(year, month - 1, day);
};

// Helper function to format date for db3 (Firebase format)
const formatDateForDb3 = (date) => {
  if (!date) return null;

  // If it's a string, try to parse it
  if (typeof date === 'string') {
    date = parseDateString(date);
  }

  // Return null if date is invalid
  if (!date || isNaN(date.getTime())) {
    console.error('Invalid date:', date);
    return null;
  }

  return date.toISOString();
};

// Helper function to format date for main db (DD-MM-YYYY)
const formatDateForMainDb = (date) => {
  if (!date) return null;

  // If it's a string, try to parse it
  if (typeof date === 'string') {
    date = parseDateString(date);
  }

  // Return null if date is invalid
  if (!date || isNaN(date.getTime())) {
    console.error('Invalid date:', date);
    return null;
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Find company by name in db2
const findCompanyByName = async (buyerName) => {
  try {
    if (!buyerName) {
      console.error('No buyer name provided');
      return null;
    }

    console.log('Searching for company by buyer name:', buyerName);
    const companiesRef = ref(db2, 'companies');
    const snapshot = await get(companiesRef);
    
    if (!snapshot.exists()) {
      console.error('Companies not found in db2');
      return null;
    }

    const companies = snapshot.val();
    console.log('Found companies:', Object.keys(companies).length);
    
    // Try exact match first
    const exactMatch = Object.values(companies).find(c => 
      c.ime.toLowerCase().trim() === buyerName.toLowerCase().trim()
    );

    if (exactMatch) {
      console.log('Found company by exact match:', exactMatch);
      return exactMatch;
    }

    // Try partial match if exact match fails
    const partialMatch = Object.values(companies).find(c => {
      const companyName = c.ime.toLowerCase();
      const searchName = buyerName.toLowerCase();
      return companyName.includes(searchName) || searchName.includes(companyName);
    });

    if (partialMatch) {
      console.log('Found company by partial match:', partialMatch);
      return partialMatch;
    }

    // Log all company names for debugging
    console.log('Available company names:', 
      Object.values(companies).map(c => c.ime)
    );
    
    console.error('Company not found by buyer name:', buyerName);
    return null;
  } catch (error) {
    console.error('Error finding company by name:', error);
    return null;
  }
};

// Add a single invoice to analytics DB
export const addInvoiceToAnalytics = async (order) => {
  try {
    console.log('Starting addInvoiceToAnalytics with order:', order);

    // Check for various ID fields
    const orderId = order.id || order.firebaseID || order.OrderID || order.OrdderID;
    if (!orderId) {
      console.error('Analytics: Order ID is missing. Available fields:', Object.keys(order));
      return null;
    }

    if (!order.Buyer) {
      console.error('Analytics: Buyer name is missing from order');
      return null;
    }

    console.log('Finding company with buyer name:', order.Buyer);
    
    // Find company by buyer name in db2
    const company = await findCompanyByName(order.Buyer);
    if (!company) {
      console.error('Company not found in db2:', order.Buyer);
      return null;
    }

    console.log('Found company:', company);
    
    // Ensure order has an id field for prepareInvoiceData
    const orderWithId = {
      ...order,
      id: orderId
    };
    
    const invoiceData = prepareInvoiceData(orderWithId, company);
    if (!invoiceData) {
      console.error('Failed to prepare invoice data');
      return null;
    }

    // Format dates for db3
    const now = new Date();

    // Handle date_of_invoice
    if (invoiceData.date_of_invoice) {
      const formattedDate = formatDateForDb3(invoiceData.date_of_invoice);
      if (formattedDate) {
        invoiceData.date_of_invoice = formattedDate;
      }
    }

    // Handle date_of_issue
    if (invoiceData.date_of_issue) {
      const formattedDate = formatDateForDb3(invoiceData.date_of_issue);
      if (formattedDate) {
        invoiceData.date_of_issue = formattedDate;
      }
    } else {
      invoiceData.date_of_issue = now.toISOString();
    }

    // Handle date_of_payment_received
    if (invoiceData.date_of_payment_received) {
      const formattedDate = formatDateForDb3(invoiceData.date_of_payment_received);
      if (formattedDate) {
        invoiceData.date_of_payment_received = formattedDate;
      }
    }

    // Map the order status to payment status
    invoiceData.payment_status = ORDER_STATUS_TO_PAYMENT_STATUS[order.Status] || 'Unpaid';
    
    // If order is archived, set payment received date
    if (order.Status === 'Archived' && !invoiceData.date_of_payment_received) {
      invoiceData.date_of_payment_received = now.toISOString();
    }

    // Get the current analytics data
    const analyticsRef = ref(db3, `invoice_details/${orderId}`);
    logDbOperation('SET', `invoice_details/${orderId}`, invoiceData);
    
    console.log('Setting data in DB3 with dates:', {
      date_of_issue: invoiceData.date_of_issue,
      date_of_invoice: invoiceData.date_of_invoice,
      date_of_payment_received: invoiceData.date_of_payment_received
    });
    
    await set(analyticsRef, invoiceData);
    console.log('Successfully set data in DB3');

    return orderId;
  } catch (error) {
    console.error('Error adding invoice to analytics:', error);
    return null;
  }
};

// Add multiple invoices to analytics DB
export const addMultipleInvoicesToAnalytics = async (orders) => {
  try {
    const invoiceUpdates = {};

    // Process orders sequentially to avoid too many concurrent db2 requests
    for (const order of orders) {
      if (!order.id && !order.firebaseID && !order.OrderID && !order.OrdderID) {
        console.error('Order ID is missing for order:', order);
        continue;
      }

      if (!order.Buyer) {
        console.error('Buyer name is missing from order:', order);
        continue;
      }

      const buyerName = order.Buyer;
      console.log('Finding company with buyer name:', buyerName);
      
      // Find company by buyer name in db2
      const company = await findCompanyByName(buyerName);
      if (!company) {
        console.error('Company not found in db2:', buyerName);
        continue;
      }

      console.log('Found company:', company);
      
      // Ensure order has an id field for prepareInvoiceData
      const orderId = order.id || order.firebaseID || order.OrderID || order.OrdderID;
      const orderWithId = {
        ...order,
        id: orderId
      };
      
      const invoiceData = prepareInvoiceData(orderWithId, company);
      if (!invoiceData) continue;
      
      // Format dates for db3
      if (invoiceData.date_of_invoice) {
        invoiceData.date_of_invoice = formatDateForDb3(invoiceData.date_of_invoice);
      }
      if (invoiceData.date_of_payment_received) {
        invoiceData.date_of_payment_received = formatDateForDb3(invoiceData.date_of_payment_received);
      }

      // Map the order status to payment status
      invoiceData.payment_status = ORDER_STATUS_TO_PAYMENT_STATUS[order.Status] || 'Unpaid';
      
      // Set payment received date if status is Archived
      if (order.Status === 'Archived') {
        invoiceData.date_of_payment_received = formatDateForDb3(new Date());
      }
      
      invoiceUpdates[orderId] = invoiceData;
    }

    if (Object.keys(invoiceUpdates).length === 0) return [];

    // Update analytics data
    const updates = {};
    Object.entries(invoiceUpdates).forEach(([orderId, data]) => {
      updates[`invoice_details/${orderId}`] = data;
    });

    logDbOperation('UPDATE', 'invoice_details', updates);
    await update(ref(db3), updates);

    return Object.keys(invoiceUpdates);
  } catch (error) {
    console.error('Error adding multiple invoices to analytics:', error);
    return [];
  }
};

// Update invoice status in analytics DB
export const updateInvoiceStatusInAnalytics = async (orderId, status, paymentDate = null) => {
  try {
    console.log('Updating invoice status in analytics:', { orderId, status, paymentDate });
    
    if (!orderId) {
      console.error('Order ID is required for status update');
      return false;
    }

    // Get current invoice data
    const invoiceRef = ref(db3, `invoice_details/${orderId}`);
    const snapshot = await get(invoiceRef);
    
    if (!snapshot.exists()) {
      console.error('Invoice not found in analytics DB:', orderId);
      return false;
    }

    const currentData = snapshot.val();
    console.log('Current invoice data:', currentData);

    // Update payment status
    const updates = {
      payment_status: ORDER_STATUS_TO_PAYMENT_STATUS[status] || status,
    };

    // Set payment date if provided, format for db3
    if (paymentDate) {
      updates.date_of_payment_received = formatDateForDb3(new Date(paymentDate));
    }

    console.log('Applying updates:', updates);
    await update(invoiceRef, updates);
    console.log('Invoice status updated successfully');

    return true;
  } catch (error) {
    console.error('Error updating invoice status:', error);
    return false;
  }
};
