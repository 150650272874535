// src/App.js
import './App.css';
import TableHome from './pages/TableHome';
import SignIn from './pages/LoginPage';
import ProfileSettings from './pages/ProfileSettings';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NavBar from './elements/NavBar';
import AdminPanel from './pages/AdminPanel';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { auth, db } from './firebase';
import { setPersistence, browserSessionPersistence } from 'firebase/auth';
import PrivateRoute from './elements/PrivateRoute';
import Archive from './pages/Archive';
import DebtorsPage from './pages/DebtorsPage';
import CompanyPage from './pages/CompaniesPage';
import EventPage from './pages/EventPage';
import Dashboard from './pages/Dashboard';
import LoadingScreen from './components/LoadingScreen';
import { version } from './version';
import OfflineAlert from './elements/OfflineAlert';

function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Set auth persistence to session
    setPersistence(auth, browserSessionPersistence)
      .catch((error) => {
        console.error('Error setting auth persistence:', error);
      });

    const fetchUserRole = async (currentUser) => {
      if (currentUser) {
        try {
          const userRef = ref(db, `users/${currentUser.uid}/role`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setUserRole(snapshot.val());
          } else {
            setUserRole('undefined');
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setUserRole('undefined');
        }
      }
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserRole(currentUser);
      } else {
        setLoading(false);
      }
      setAuthInitialized(true);
    });

    // Handle online/offline events
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    auth.signOut();
  };

  // Show loading screen while auth is initializing
  if (!authInitialized || loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <div className="nav">
        {user && <NavBar user={user} onLogout={handleLogout} />}
      </div>
      <div className='App'>
        {!isOnline && <OfflineAlert />}
        <Routes>
          <Route
            exact
            path="/"
            element={
              user ? (
                <TableHome userName={user.displayName} userRole={userRole} user={user} />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
          <Route
            path="/signin"
            element={
              user ? (
                <Navigate to="/" replace />
              ) : (
                <SignIn />
              )
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/adminPanel"
            element={
              <PrivateRoute
                element={<AdminPanel />}
                user={user}
                userRole={userRole}
                requiredRole="admin"
              />
            }
          />
          <Route
            path="/debtors"
            element={
              <PrivateRoute
                element={<DebtorsPage userRole={userRole}/>}
                user={user}
              />
            }
          />
          <Route
            path="/archive"
            element={
              <PrivateRoute
                element={<Archive userRole={userRole}/>}
                user={user}
              />
            }
          />
          <Route
            path="/activities"
            element={
              <PrivateRoute
                element={<EventPage user={user} userRole={userRole}/>}
                user={user}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute
                element={<ProfileSettings user={user} />}
                user={user}
              />
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute
                element={<CompanyPage/>}
                user={user}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={<Dashboard userRole={userRole} user={user}/>}
                user={user}
              />
            }
          />
        </Routes>
      </div>
      <footer className="copyrightBanner">
        <p>&copy; {new Date().getFullYear()} Teodor Nedevski licensed to Somitek Makedonija dooel. All rights reserved. | v{version}</p>
      </footer>
    </Router>
  );
}

export default App;
