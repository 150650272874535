import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import './Analytics.css';

const colors = ['#8884d8', '#82ca9d', '#8dd3c7', '#bebada', '#fb8072'];

const TopCompaniesChart = ({ data, metric, unit, onItemClick }) => {
  const calculateChartData = () => {
    if (!data || data.length === 0) return [];

    const companyData = {};

    data.forEach(item => {
      const company = item.company_name;
      if (!company) return;

      if (!companyData[company]) {
        companyData[company] = {
          orders: 0,
          incomeMKD: 0,
          incomeEUR: 0,
          latencySum: 0,
          latencyCount: 0
        };
      }

      companyData[company].orders++;
      
      const amount = parseFloat(item.amount);
      if (item.currency === 'MK-MKD') {
        companyData[company].incomeMKD += amount;
      } else {
        companyData[company].incomeEUR += amount;
      }

      if (item.date_of_payment_received && item.date_of_issue) {
        const latency = Math.floor(
          (new Date(item.date_of_payment_received) - new Date(item.date_of_issue)) / 
          (1000 * 60 * 60 * 24)
        );
        if (latency >= 0) {
          companyData[company].latencySum += latency;
          companyData[company].latencyCount++;
        }
      }
    });

    return Object.entries(companyData)
      .map(([name, values]) => {
        let value;
        switch (metric) {
          case 'latency':
            value = values.latencyCount > 0 
              ? Math.round(values.latencySum / values.latencyCount)
              : 0;
            break;
          case 'income':
            value = values.incomeEUR + (values.incomeMKD / 61.5);
            break;
          default:
            value = values.orders;
        }
        return { name, value };
      })
      .sort((a, b) => metric === 'latency' ? b.value - a.value : b.value - a.value)
      .slice(0, 5);
  };

  const chartData = calculateChartData();

  return (
    <div className="top-companies-chart">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" width={150} />
          <Tooltip
            formatter={(value) => {
              if (unit === 'EUR') {
                return value.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                });
              }
              return [value.toLocaleString(), unit];
            }}
          />
          <Bar 
            dataKey="value" 
            fill="#8884d8"
            onClick={(data) => onItemClick(data.name)}
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopCompaniesChart;
